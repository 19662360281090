{
  "columns": [
    [
      {
        "url": "https://grad.arizona.edu/admissions",
        "text": "Graduate Admissions"
      },
      {
        "url": "https://grad.arizona.edu/gsas",
        "text": "Graduate Student Academic Services"
      },
      {
        "url": "https://grad.arizona.edu/funding",
        "text": "Funding Resources"
      },
      {
        "url": "https://grad.arizona.edu/policies",
        "text": "Policies"
      }
    ],
    [
      {
        "url": "https://gradcenter.arizona.edu",
        "text": "The Graduate Center"
      },
      {
        "url": "https://apply.grad.arizona.edu",
        "text": "GradApp (Future Students)"
      },
      {
        "url": "https://grad.arizona.edu/gradpath",
        "text": "GradPath (Current Students)"
      },
      {
        "url": "https://grad.arizona.edu/contact",
        "text": "Contact Us"
      }
    ]
  ]
}
